@font-face {
  font-family: 'Nunito';
  src: url(./fonts/Nunito/Nunito-Regular.ttf) format('truetype');
}

*, *:before, *:after {
  box-sizing: border-box;
  font-family: 'Nunito';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General Styles */

h1 {
  color: rgba(254, 121, 56, 1);
}

h2, h3, h4, h5, h6 {
  color: rgba(0, 11, 67, 1);
}

p {
  color: rgb(99, 99, 99, 1);
  /* line-height: 140%; */
  text-align: justify;
  font-size: larger;
}

.card, .cardhover {
  padding: 20px 20px;
  background-color: white;
  box-shadow: 0 0 1.0rem rgba(105, 105, 105, 0.3);
  border-radius: 20px;
  margin-bottom: 30px;
  transition: box-shadow 0.3s ease-in-out;
}

.cardhover:hover {
  box-shadow: 0 0 2.5rem rgba(105, 105, 105, 0.3);
}

/* Styles for buttons, inputs and forms */
a {
  text-decoration: none;
  color: rgba(254, 121, 56, 1);
}

input[type=file] {
  display: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.Button {
  padding: 10px 20px;
  border: 2px solid rgba(254, 121, 56, 1);
  border-radius: 20px; 
  background-color: unset; 
  color: rgba(254, 121, 56, 1);
  font-size: larger;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}
  
.Button:hover {
  /* background-color:rgba(0, 11, 67, 0.2); */
  box-shadow: 0.2rem 0.2rem 1.0rem rgba(105, 105, 105, 0.3);
}

.Button:active {
  /* background-color:rgba(0, 11, 67, 0.2); */
  box-shadow: 0.0rem 0.0rem 0.0 rgba(105, 105, 105, 0.3);
  transition: box-shadow 0.05s ease-in-out;
}

.Button:disabled {
  border: 2px solid rgba(0, 11, 67, 0.2);
  background-color:rgba(0, 11, 67, 0.2);
  color: rgba(0, 11, 67, 0.6);
  cursor: default;
}

input[type=text], input[type=number], select {
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-sizing: border-box;
  margin-top: 5px;
  color: rgb(99, 99, 99, 1);
  font-size: medium;
}

button[type=normal] {
  border: 2px solid rgba(0, 11, 67, 1);
  color: rgba(0, 11, 67, 1);
}

.blue-button, .red-button {
  padding: 10px 20px;
  border: 2px solid rgba(0, 11, 67, 1);
  color: rgba(0, 11, 67, 1);
  border-radius: 20px; 
  background-color: unset; 
  font-size: larger;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.red-button {
  color: rgba(254, 57, 36, 1);
  border: 2px solid rgba(254, 57, 36, 1);
}

.x-button {
  cursor: pointer;
  color: rgb(99, 99, 99, 1);
}

label {
  width: 100%;
  margin-top: 1rem;
  display: inline-block;
  color: rgb(99, 99, 99, 1);
  font-size: medium;
}

.customDatePickerWidth,
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.error {
  color: red;
  font-size: small;
}

.warning {
  color: rgba(255, 108, 60);
  font-size: small;
}

.first-label {
  margin-top: 0;
}

/* Table Styles */

.heading-chart {
  margin-left: 10px;
  margin-bottom: 0;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgba(0, 11, 67, 1);
  color: white;
}

.ul, li {
  color: rgb(99, 99, 99, 1);
  text-align: justify;
  font-size: larger;
  line-height: 140%;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.Collapsible__contentInner {
  padding-left: 20px;
  padding-right: 20px;
}

.rotate {
  transform: rotate(90deg);
}

select{
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 99% 50%;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
}

/* Layout Styles */
.LayoutContainer {
  padding: 16px;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;
}

@media screen and (max-width: 1500px) {
  .LayoutContainer {
      width: 80%;
  }
}

/* Navbar Styles */
.Navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0px 10%;
  overflow: hidden;
  background-color: #fff;
  font-weight: bold;
  font-size: large;
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
}

.Navbar li {
  float: left;
}

.Navitem span {
  float: left;
  display: block;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  display: block;
  color: rgba(0, 11, 67, 1);
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  cursor: pointer;
}

.Navitem span:hover {
  border-bottom: 3px solid #ccc;
}

/* Modal Styles */
.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  max-height: 80%;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  overflow-x: hidden;
  overflow-y: auto;
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Profile styles */
.user {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  
  object-fit: cover;
}

/* Carousel styles */
.slick-slide {
  margin-top: 20px;
  margin-bottom: 20px;
}
/* .center .slick-center {
  transform: scale(1.08);
} */
.slick-prev {
  left: 110px !important;
  z-index: 1;
}
.slick-prev:before, .slick-next:before {
  color: black !important;
}
.slick-next {
  right: 110px !important;
  z-index: 1;
}
